import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { StyledPaper } from '../component/shared/StyledComponents';
import SlsPasirinkimai from '../component/SlsKitimas/SlsPasirinkimai';
import { useAuth } from '../context/AuthProvider';
import { useMenu } from '../context/MenuProvider';
import { getItem } from '../Api/Api';
import SlsKreiviuGrafikas from '../component/SlsKitimas/SlsKreiviuGrafikas';
import SlsStulpeliuGrafikas from '../component/SlsKitimas/SlsStulpeliuGrafikas';
import {
  LaktacijuAmziai,
  LaktacijuTrukmes,
} from '../constants/ParametruGrupes';

const BandosSlsKitimas = () => {
  const auth = useAuth();
  const menu = useMenu();
  const arSavininkas = auth.user.role === 'Ūkis';

  const [isLoading, setIsLoading] = useState(false);
  const [duomenys, setDuomenys] = useState([]);
  const [banda, setBanda] = useState(0);
  const [veisle, setVeisle] = useState(0);
  const [laktacija, setLaktacija] = useState(1);
  const [laktTrukme, setLaktTrukme] = useState(1);

  useEffect(() => {
    if (menu.savininkas !== '' || arSavininkas) {
      setIsLoading(true);
      setVeisle(0);
      const url = `savininkai/banda/sls?savininkas=${
        arSavininkas ? '' : menu.savininkas
      }`.concat(banda !== 0 ? `&banda=${banda}` : '');
      getItem(url)
        .then((data) => {
          data.data && setDuomenys(data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setDuomenys([]);
          if (err.response.status === 401) {
            auth.logout();
          }
        });
    } else {
      setBanda(0);
      setVeisle(0);
      setDuomenys([]);
    }
  }, [menu.savininkas, arSavininkas, banda, auth]);

  const veisles = [{ id: 0, label: 'Visos' }].concat([
    ...new Map(
      duomenys
        .flatMap((d) => d.duomenys.veisles)
        .map((item) => [item['id'], { id: item.id, label: item.veisle }])
    ).values(),
  ]);

  let vidurkiai = duomenys.map((d) => ({
    menuo: d.menuo,
    visos: d.duomenys.vidurkis,
  }));

  const infekuotos = duomenys.map((d) => ({
    menuo: d.menuo,
    infekuotu: d.duomenys.infekuotu,
    virs200: d.duomenys.virs200,
    reiksme: Number(
      ((d.duomenys.infekuotu / d.duomenys.viso) * 100).toFixed(2)
    ),
  }));

  const slsGrupes = duomenys.map((item) =>
    item.duomenys.slsGrupes.reduce((acc, curr) => ({ ...acc, ...curr }), {
      menuo: item.menuo,
    })
  );

  const infekVeisles = infekuotos.map((d) => {
    let obj = { menuo: d.menuo, visos: d.virs200 };
    return obj;
  });

  if (veisle !== 0) {
    duomenys.forEach((d, index) => {
      let temp = d.duomenys.veisles.find((v) => v.id === veisle);
      if (temp) {
        vidurkiai[index][`${temp.veisle}`] = temp.vidurkis;
        infekVeisles[index][`${temp.veisle}`] = temp.infekuotu;
        infekVeisles[index].kitos = infekVeisles[index].visos - temp.infekuotu;
      } else {
        infekVeisles[index].kitos = infekVeisles[index].visos;
      }
    });
  }

  let veisliuRaktai = [];
  try {
    veisliuRaktai = Object.keys(vidurkiai[0]).filter((k) => k !== 'menuo');
  } catch {
    veisliuRaktai = ['menuo', 'visos'];
  }

  const laktDuom = LaktacijuAmziai.find((item) => item.id === laktacija);
  const laktAmziai = duomenys.map((d) => ({
    menuo: d.menuo,
    visos: d.duomenys.vidurkis,
    [`${laktDuom.label}`]:
      d.duomenys.laktacijos.reduce((acc, curr) => ({ ...acc, ...curr }), {})[
        laktDuom.label
      ] || 0,
  }));

  const laktTrDuom = LaktacijuTrukmes.find((item) => item.id === laktTrukme);
  const laktTrukmes = duomenys.map((d) => ({
    menuo: d.menuo,
    visos: d.duomenys.vidurkis,
    [`${laktTrDuom.label}`]:
      d.duomenys.stadijos.reduce((acc, curr) => ({ ...acc, ...curr }), {})[
        laktTrDuom.label
      ] || 0,
  }));

  return (
    <>
      <Container maxWidth='100vw' sx={{ mt: 4, mb: 4 }}>
        <Typography variant='h4' sx={{ marginBottom: '20px' }}>
          Bandos SLS rodiklių kitimas
        </Typography>
        <Grid container spacing={1}>
          <Grid container item justifyContent='center' spacing={2} mb={2}>
            <Grid item xs={12} lg={2}>
              <StyledPaper style={{ height: 600 }}>
                <SlsPasirinkimai
                  banda={banda}
                  setBanda={setBanda}
                  veisles={veisles}
                  veisle={veisle}
                  setVeisle={setVeisle}
                  laktacija={laktacija}
                  setLaktacija={setLaktacija}
                  laktTrukme={laktTrukme}
                  setLaktTrukme={setLaktTrukme}
                />
              </StyledPaper>
            </Grid>
            <Grid container item xs={12} lg={10} spacing={2}>
              <Grid item xs={12} lg={6}>
                <StyledPaper style={{ height: 290 }}>
                  <SlsKreiviuGrafikas
                    isLoading={isLoading}
                    pavadinimas='Bandos SLS vidurkis'
                    data={vidurkiai}
                    keys={['visos']}
                  />
                </StyledPaper>
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledPaper style={{ height: 290 }}>
                  <SlsKreiviuGrafikas
                    isLoading={isLoading}
                    pavadinimas='Karvių skaičius, kurių SLS > 400(%)'
                    data={infekuotos}
                    keys={['reiksme']}
                  />
                </StyledPaper>
              </Grid>
              <Grid item xs={12} lg={12}>
                <StyledPaper style={{ height: 290 }}>
                  <SlsStulpeliuGrafikas
                    isLoading={isLoading}
                    pavadinimas='Karvių sk. pagal SLS grupes'
                    data={slsGrupes}
                    keys={['0-200', '201-400', '401-1000', '>1000']}
                  />
                </StyledPaper>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item justifyContent='center' spacing={2} mb={2}>
            <Grid item xs={12} lg={6}>
              <StyledPaper style={{ height: 300 }}>
                <SlsKreiviuGrafikas
                  isLoading={isLoading}
                  pavadinimas='SLS pagal karvių veisles'
                  data={vidurkiai}
                  keys={veisliuRaktai}
                />
              </StyledPaper>
            </Grid>
            <Grid item xs={12} lg={6}>
              <StyledPaper style={{ height: 300 }}>
                <SlsStulpeliuGrafikas
                  isLoading={isLoading}
                  pavadinimas='Karvių sk., kurių SLS > 200, pagal veisles'
                  data={infekVeisles}
                  keys={
                    veisle !== 0
                      ? ['kitos', ...veisliuRaktai.slice(1)]
                      : ['visos']
                  }
                />
              </StyledPaper>
            </Grid>
          </Grid>
          <Grid container item justifyContent='center' spacing={2} height={300}>
            <Grid item xs={12} lg={6}>
              <StyledPaper style={{ height: 300 }}>
                <SlsKreiviuGrafikas
                  isLoading={isLoading}
                  pavadinimas='Atskirų laktacijų SLS kitimas'
                  data={laktAmziai}
                  keys={['visos', laktDuom.label]}
                />
              </StyledPaper>
            </Grid>
            <Grid item xs={12} lg={6}>
              <StyledPaper style={{ height: 300 }}>
                <SlsKreiviuGrafikas
                  isLoading={isLoading}
                  pavadinimas='Laktacijų stadijų SLS kitimas'
                  data={laktTrukmes}
                  keys={['visos', laktTrDuom.label]}
                />
              </StyledPaper>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default BandosSlsKitimas;
